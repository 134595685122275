// Scripts for the product media component
import '../../../styles/components/general/product-media.scss';

import Swiper from 'swiper';
import { Controller, A11y, Navigation } from 'swiper/modules';
import Player from '@vimeo/player';

Swiper.use([Controller, Navigation, A11y]);

// Product slides
if( document.querySelector( '.product-media__slider') ) {
  let productMedia = new Swiper( // eslint-disable-line no-unused-vars
    '.product-media__media',
    {
      loop: true,
      navigation: {
        nextEl: '.product-media__button--next',
        prevEl: '.product-media__button--prev'
      },
      preloadImages: false,
      slidesPerView: 'auto',
      spaceBetween: 64
    }
  );
}

// Dialog management
let mediaItem = document.querySelectorAll('.product-media__item');
Array.prototype.forEach.call( mediaItem, function( button ) {
  button.onclick = function() {
    let modal = document.getElementById( 'product-media-' + button.dataset.index );
    modal.showModal();

    let iframe = document.getElementById( 'video-' + button.dataset.index );
    iframe.src = iframe.dataset.source;
    let player = new Player( iframe );

    modal.addEventListener('close', function() {
      player.pause().catch(function(error) {
        switch (error.name) {
          case 'PasswordError':
            // the video is password-protected and the viewer needs to enter the
            // password first
            break;

          case 'PrivacyError':
            // the video is private
            break;

          default:
            // some other error occurred
            break;
        }
      });
    });
  }
});


let close = document.getElementsByClassName('product-media__close');
Array.prototype.forEach.call( close, function( button ) {
  button.onclick = function() {
    let index = button.dataset.index;
    document.getElementById( 'product-media-' + index ).close();
  }
});
